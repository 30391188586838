const tradeConfirmation = {
    manual: "报价交易",
    otcDirectSettlement: "OTC Direct 结算",
    otcDirectDeposit:'OTC Direct 入金',
    tradeConfirmationRecord: "交易确认记录",
    // transactionConfirmationDisclaimer
    transactionConfirmationDisclaimer: "交易确认免责声明",
    transactionConfirmationDisclaimer1:
        "这些确认信息可能包含保密和（或）法律特权信息。如果您不是交易人，请通过电话通知交易人或忽略此确认信息。除交易人外，任何人都不得使用或分发此确认信息。",
    transactionConfirmationDisclaimer2:
        'VirgoCX Direct Inc. (“VirgoCX”) 严禁涉及未知或未披露第三方的交易。向我们提供交易指令,即表示您承认已向VirgoCX披露所有参与的第三方。由于投资骗局和其他类型的欺诈活动盛行，许多加密货币投资者遭受大量的损失。向我们提供交易指令，即表示您承认VirgoCX不对VirgoCX控制范围之外的损失负责。一旦您的加密货币按照您的指示从我们的钱包转移到受益人的钱包，VirgoCX就无法再控制您的加密货币。通过向我们提交交易指令，您同意赔偿VirgoCX在您的加密货币离开VirgoCX控制的钱包后发生的损失。',
    confirm: "确认",
    deny: "拒绝",

    // table
    dateAndTime:'日期与时间',
    tradeId:'交易号',
    tradeInformation:'交易信息',
    status:'状态',
    action:'操作',

    newTrade:'New Trade',
    confirmed:'Confirmed',
    paymentProcessed:'Payment Processed',
    cancelled:'Cancelled',
    rejected:'Rejected',
    pendingReject:'Pending Reject',
    tradeUpdate:'Trade Update',

    // detail
    tradeConfirmation:'交易确认',
    tradeId:'交易号',
    status:'状态',
    statusUpdateTime:'状态更新时间',
    note:'备注',
    confirmTradeMessage:'请在下方确认交易信息并按照指示执行以下交易内容。',
    thankYouMessage:'感谢您选择VirgoCX。',
    confirmYourTransaction:'确认您的交易',
    inputConfirmHere:'请在此处输入“确认”。',
    inputRejectPrompt:'请在此处输入“拒绝”。',
    confirm:'确认',
    reject:'拒绝',
    typeHere:'在此输入',
    cancel:'取消',
    process:'处理',

    hiContext: "您好{{clientName}},",
    tradeConfirmationHeader: "这是您的交易确认信息。",
    dateTimeContext: "交易日期与时间：",
    clientNameContext: "客户姓名：{{clientName}}",
    actionContext: "操作: ",
    digitalCurrencyContext: "数字货币: ",
    fiatCurrencyContext: "法币: ",
    totalCryptoAmountContext: "买方（待）接收的数量: ",
    pricePerTokenContext: "货币单价: ",
    totalFiatAmountContext: "可交付给卖方的法定货币总额: {{totalFiatAmount}} {{fiatCurrency}}",
    receivingWalletContext: "买方钱包地址: ",
    wireTransferInformationContext: "卖方电汇说明: ",

    transactionConfirmed:'交易已确认',
    transactionConfirmedMessage:'谢谢！您的交易已成功确认。',
    transactionRejected:'交易已拒绝',
    transactionRejectedMessage:'您已成功拒绝该交易。没有任何付款或加密货币转账被处理。',
    Done:'完成',

    settlementAmount: "结算金额: ",
    settlementWireInfo: "电汇结算信息：",
    receivingWalletAddress: "接收钱包地址：",
    otcDirectUsername: "OTC Direct 用户名：",
    conductorsName: "操作员姓名：",

    depositAmount: "入金金额: ",
    depositWireInstru: "电汇入金说明：",
    depositWalletAddress: "入金钱包地址：",
    quantityToSeller: "可交付卖方的数量：",
    buyerWireTransferInstru: "买方电汇说明: ",
    sellersWalletAddress: "卖房钱包地址：",
    exRate: "汇率：",
};
export default tradeConfirmation;
