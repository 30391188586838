import { create } from "zustand";
import MemberProfileAPI from "fetch/memberProfile";

const useMember = create((set) => ({
    
    //用户信息
    memberInfo: {}, 

    userProfile: {},

    // has been navigated to 2fa page
    navigated:false,

    // 用户安全信息
    securityInfo:{},

    recordNavigated:()=>{
        set((state) => ({
            ...state,
            navigated: true,
        }))
    },

    resetMemberInfo: () =>{
        localStorage.removeItem('wealthAuth');
        localStorage.removeItem('step')
        localStorage.removeItem('subStep')
        localStorage.removeItem('personalProfile')

        localStorage.removeItem('entityProfile')
        localStorage.removeItem('entityStep')
        localStorage.removeItem('subEntityStep')
        
        set((state) => ({
            ...state,
            memberInfo: {},
        }))
    },
    
    setMemberInfo: (v) =>{
        localStorage.setItem("wealthAuth", JSON.stringify(v));//存储
        set((state) => ({
            ...state,
            memberInfo: v,
        }))
    },

    setUserProfile: async () => {
        const result = await MemberProfileAPI.getUserProfile()
        set((state) => ({
            ...state,
            userProfile: result.data,
        }))
    },

    setSecurityInfo: async () => {
        const result = await MemberProfileAPI.getSecurityInfo()
        set((state) => ({
            ...state,
            securityInfo: result.data,
        }))
    },
    

   
}));

export default useMember;
