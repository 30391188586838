import "./App.css";
import React, { useEffect } from "react";
import { ConfigProvider, message } from "antd";
import "./i18n";
import RoutesComponents from "./router";

function App() {
    useEffect(() => {
        //防止clickjacking
        if (window.top != window.self && window.top.location.origin != window.self.location.origin) {
            localStorage.removeItem("wealthAuth");
            localStorage.removeItem("step");
            localStorage.removeItem("subStep");
            localStorage.removeItem("personalProfile");
            localStorage.removeItem('entityProfile')
            localStorage.removeItem('entityStep')
            localStorage.removeItem('subEntityStep')
            window.top.location = window.self.location; // 替换顶级窗口的地址
        }
        message.config({
            duration: 5,
            top: 60, // 由于延时增加导致trade按钮被遮挡，特降低提示信息高度
        });
    }, []);
    return (
        <ConfigProvider theme={{ token: { colorPrimary: "#05004D" } }}>
            <RoutesComponents />
        </ConfigProvider>
    );
}

export default App;
