import { create } from "zustand";

const useFormOnboardingStore = create((set) => ({
    /**
     * ** possible data **
     * 
     * onboardingFormUrl
     * idUrlList
     * addressUrlList
     * onboardingSupportUrlList
     * updateType - 0 Amendment, 1 Sof
     * otcAccountType
     * auditMethod
     * amendmentUrlList
     * sofUrlList
     * supportUrlList - other
     */
    formOnboarding: {},
    setOrAddFormOnboardInfo: (v) => {
        set((state) => ({
            ...state,
            formOnboarding: { ...state.formOnboarding, ...v },
        }));
    },
    resetFormOnboarding:()=>{
        set((state) => ({...state, formOnboarding: {} }));
    }
}));

export default useFormOnboardingStore;
