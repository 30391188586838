const tradeConfirmation = {
    manual: "Manual",
    otcDirectSettlement: "OTC Direct Settlement",
    otcDirectDeposit:'OTC Direct Deposit',
    tradeConfirmationRecord: "Trade Confirmation Record",
    // transactionConfirmationDisclaimer
    transactionConfirmationDisclaimer: "Transaction Confirmation Disclaimer",
    transactionConfirmationDisclaimer1:
        "These confirmations may contain confidential and/or legally privileged information. If you are not the intended recipient, please notify the sender by telephone or ignore this confirmation. Any use or distribution of this confirmation by anyone other than the intended recipient is prohibited.",
    transactionConfirmationDisclaimer2:
        'VirgoCX Direct Inc. ("VirgoCX") prohibits transactions involving unknown or undisclosed third parties. By providing us with transaction instructions, you acknowledge that all third party involvement has been disclosed to VirgoCX. Due to the prevalence of investment scams and other types of fraudulent activities, many cryptocurrency investors have lost significant sums of their investments. By providing us with transaction instructions, you acknowledge that VirgoCX cannot be held liable for losses that occur outside the control of VirgoCX. VirgoCX has no further control of your cryptocurrency once your cryptocurrency has departed our wallets towards the beneficiary wallet as per your instructions. By submitting transaction instructions to us, you agree to indemnify VirgoCX for losses that occur after your cryptocurrency has departed a wallet within VirgoCX\'s control.',
    confirm: "Confirm",
    deny: "Deny",

    // table
    dateAndTime:'Date & Time',
    tradeId:'Trade ID',
    tradeInformation:'Trade Information',
    status:'Status',
    action:'Action',

    newTrade:'New Trade',
    confirmed:'Confirmed',
    paymentProcessed:'Payment Processed',
    cancelled:'Cancelled',
    rejected:'Rejected',
    pendingReject:'Pending Reject',
    tradeUpdate:'Trade Update',

    // detail
    tradeConfirmation:'Trade Confirmation',
    tradeId:'Trade ID',
    status:'Status',
    statusUpdateTime:'Status Update Time',
    note:'Note',
    confirmTradeMessage:'Please confirm the trade information below and process the transaction indicated.',
    thankYouMessage:'Thank you for choosing VirgoCX for your OTC needs.',
    confirmYourTransaction:'Confirm your transaction',
    inputConfirmHere:'Please input “Confirm” here.',
    inputRejectPrompt:'Please input “Reject” here.',
    confirm:'Confirm',
    reject:'Reject',
    typeHere:'Type here',
    cancel:'Cancel',
    process:'Process',

    hiContext: "Hi {{clientName}},",
    tradeConfirmationHeader: "Here is your trade confirmation.",
    dateTimeContext: "Date/Time of Transaction: ",
    clientNameContext: "Client Name: {{clientName}}",
    actionContext: "Direction: ",
    digitalCurrencyContext: "Currency: ",
    fiatCurrencyContext: "Counter Currency: ",
    totalCryptoAmountContext: "Quantity (to be) Received by Buyer: ",
    pricePerTokenContext: "Price Per Token: ",
    totalFiatAmountContext: "Total Fiat Currency Deliverable to Seller: {{totalFiatAmount}} {{fiatCurrency}}",
    receivingWalletContext: "Buyer's Wallet Address: ",
    wireTransferInformationContext: "Seller's Wire Transfer Instructions: ",

    transactionConfirmed:'Transaction Confirmed',
    transactionConfirmedMessage:'Thank you! Your transaction has been successfully confirmed.',
    transactionRejected:'Transaction Rejected',
    transactionRejectedMessage:'You have successfully rejected the transaction. No payment/crypto transfer processed.',
    Done:'Done',

    settlementAmount: "Settlement amount: ",
    settlementWireInfo: "Settlement Wire Transfer Info: ",
    receivingWalletAddress: "Receiving wallet address: ",
    otcDirectUsername: "OTC Direct User Name: ",
    conductorsName: "Conductor's Name: ",

    depositAmount: "Deposit Amount: ",
    depositWireInstru: "Deposit wire instructions: ",
    depositWalletAddress: "Deposit Wallet Address: ",
    quantityToSeller: "Quantity Deliverable to Seller: ",
    buyerWireTransferInstru: "Buyer's Wire Transfer Instructions: ",
    sellersWalletAddress: "Seller's Wallet Address: ",
    exRate: "Exchange Rate: ",
};
export default tradeConfirmation;
