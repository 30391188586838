import { useTranslation } from "react-i18next";
import imgBanner from "static/imgs/common/welcome-banner.png";
const NoMatchPage = () => {
    const { t } = useTranslation("common");
    return (
        <div className="flex justify-center w-full sm:min-w-[1440px] h-[calc(100vh-67px)] sm:h-[calc(100vh-100px)] !text-white bg-[#05004D]">
            <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mt-[20px] sm:m-0 gap-[16px] w-full sm:w-[1440px] px-[20px] sm:px-[140px]">
                <div className="flex flex-col gap-[16px] w-full sm:w-[530px]">
                    <h1>{t("notFoundH")}</h1>
                    <p>{t("notFoundC")}</p>
                </div>
                <img src={imgBanner} alt=""></img>
            </div>
        </div>
    );
};
export default NoMatchPage;
