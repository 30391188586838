const dashboard = {
    test: "test",
    account:"账号",
    security:"安全",
    submitDocs: "提交文件",
    orderHistory:"申请记录",
    setting:"设置",
    logout:'登出',
    Password:"密码",
    LoginPassword:"登录密码",
    Edit:"编辑",
    Verification:"认证",
    EmailVerification:"邮箱验证",
    Authenticator:"Google / Microsoft Authenticator （第三方确认软件）",
    StronglyRecommend:"（强烈推荐）",
    phoneNumber:'手机号码',
    enterPhoneNumberSMS:'请输入您的手机号和验证码。',
    smsVerification:'短信验证',
    googleMicrosoftAuthenticatorCode:'Google/Microsoft Authenticator验证码',
    Unbound:"未绑定",
    Delete: `删除`,
    Bound: `(已绑定)`,
    back:'返回',
    google2faContent:'请打开您的Google / Microsoft Authenticator 并扫描QR Code或粘贴密钥以添加您的virgo账户',
    Verify:"验证",
    confirm:'确认',
    dontKnow:'不清楚如何设置身份验证器？',
    googleLink:"Google Authenticator 使用说明",
    msLink:'Microsoft Authenticator 使用说明',
    ChangePassword:'请设置新的登录密码',

    // account
    email: "电子邮件",
    initH: "开户申请",
    initC: "为了完善您的验证流程，我们需要您提供一些文件",
    startApplication: "开始申请",
    verified:"已验证",
    Legalname:"法定姓名",
    IndividualOnboardingApplication:"个人开户申请",
    ViewMySubmission:"查看我的申请",
    ref:"参考：",
    Pendingonreview:"等待审核",
    ReviewedbyAccountManager:"等待最终审批",
    ESignatureApproved:"电子签名",
    SubmitSignature:"提交签名",
    signH:"电子签名",
    signC:"请提供您的个人信息",
    signRightH:"通过在下面签名，我证明本表格中提供的信息是真实，完整和准确的",
    signRightC:"我了解在点击“提交申请”后，我将无法返回并更改我的申请信息",
    signRightInput:"在此输入您的全名",
    SubmitApplication:"提交申请",
    Submitted:"已提交",
    emailAddress:'电子邮件地址',
    entitySign:`通过在下面签名，我，{{signingPerson}}，作为公司正式任命的高级官员，以其职务身份而非个人身份，证明以上所有信息属实：`,
    title:'职位',
    Signature:'签名',
    pleaseShare:"请与相关人员分享验证链接",
    openList:'打开列表',
    onLineLink:'在线验证链接',
    copyLink:'复制链接',
    haveRead:'我已阅读并同意该网页内容',
    pleaseSelect:'请选择以下中的一人签字并确认',
    selectAnInd:'请选择一个人',
    next:'下一步',
    entitySignSubTitele:'签字后，代表我确认我已经阅读，知悉并同意上面全部的条款信息',

    // Submit Documents
    contactUs: "如需更新或提交补充文件，请与我们联系。",
    customerService: "客户服务：",
    csWorkTime: '东部标准时间周一至周五9:00 - 17:00',
    infoUpdate: "更新信息",
    accountInfo: "账户信息",
    accountInfoDesc: "信息变更表、银行信息、身份证件更新等",
    fundSource: "资金来源",
    other: "其他",
    start: "开始",
    submitAdditionalInfo:'请根据要求提交补充信息。',
    submitNeededInfo:'请提交需要更新的信息。',
    amendmentFormTip:'VirgoCX Direct 信息变更表,请确保填写了所有必填项并签名',
    sofTip:'任何相关文件可用以证明您在开户时所声明的资金来源。如您对所需上传的文件有所疑问，或希望对资金来源进行更改，请先与您的客户经理联系。',
    contactRequired:'需要联系我们以继续',
    accountActive:'账户开通',
    tradeConfirmation:'交易确认',
    twoFaTitle:'2FA验证',
    confirmEmail:'确认邮箱',
    skipNow:'跳过',
    individualAmendmentForm:'个人信息变更表',
    entityAmendmentForm:'公司信息变更表',
    downloadAmendmentTip:'如果您没有信息变更表,请下载以下表格。',
};
export default dashboard;
