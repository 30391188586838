import common from "./zh/common";
import header from "./zh/header";
import loginRegister from "./zh/loginRegister";
import personalInfo from "./zh/personalInfo";
import onboarding from "./zh/onboarding";
import dashboard from "./zh/dashboard";
import tradeConfirmation from "./zh/tradeConfirmation";
const zh = {
    common,
    header,
    loginRegister,
    personalInfo,
    onboarding,
    dashboard,
    tradeConfirmation
};
export default zh;
