const dashboard = {
    test: "test",
    account:"Account",
    security:"Security",
    submitDocs: "Submit Documents",
    orderHistory:"Order History",
    setting:"Setting",
    logout:'Log Out',
    Password:"Password",
    LoginPassword:"Login Password",
    Edit:"Edit",
    Verification:"Verification",
    EmailVerification:"Email Verification",
    Authenticator:"Google / Microsoft Authenticator",
    phoneNumber:'Phone Number',
    enterPhoneNumberSMS:'Please enter a phone number that can receive SMS and all the following codes to complete.',
    smsVerification:'SMS Verification',
    googleMicrosoftAuthenticatorCode:'Google/Microsoft Authenticator Code',
    StronglyRecommend:"(Strongly Recommend)",
    Unbound:"Not Bound",
    Delete: `Delete`,
    Bound: `(Bound)`,
    back:'Back',
    google2faContent:'Please open your Google/Microsoft Authenticator app and scan the QR code or paste the key to add your Virgo account.',
    Verify:"Verify",
    confirm:'Confirm',
    dontKnow:'Don’t you know how to set up Authenticator?',
    googleLink:"Google Authenticator Instruction",
    msLink:'Microsoft Authenticator Instruction',
    ChangePassword:'Set Your New Log in Password',

    // account
    email:"Email",
    initH:"Onboarding application",
    initC:"To complete the verification process, we need some documents.",
    startApplication:"Start Application",
    verified:"Verified",
    Legalname:"Legal name",
    IndividualOnboardingApplication:"Individual Onboarding Application",
    ViewMySubmission:"View My Submission",
    ref:"Ref: ",
    Pendingonreview:"Pending on review",
    ReviewedbyAccountManager:"Waiting for final approval",
    ESignatureApproved:"E-Signature",
    SubmitSignature:"Submit Signature",
    signH:"E-Signature",
    signC:"Kindly provide your personal information.",
    signRightH:"By signing below, I attest that the information given in this form is true, complete and accurate. ",
    signRightC:"I understand that after I click “Submit Application”, I cannot come back and change my application information. ",
    signRightInput:"Enter Your Full Legal Name Here",
    SubmitApplication:"Submit Application",
    Submitted:"Submitted",
    emailAddress:'Email Address',
    entitySign:`By signing below, I, {{signingPerson}}, a duly appointed senior officer of the Corporation, in such capacity and not in personal capacity, certifies all information above:`,
    title:'Title / Role',
    Signature:'Signature',
    pleaseShare:"Please share the verification link with the respective individuals.",
    openList:'Open List',
    onLineLink:'Online Verification Link',
    copyLink:'Copy Link',
    haveRead:'I have read and agree to the website ',
    pleaseSelect:'Please select an individual to sign and confirm behalf.',
    selectAnInd:'Select an individual',
    next:'Next',
    entitySignSubTitele:'To sign below, I confirm I read, understand and agree all acknowledge statement above.',

    // Submit Documents
    contactUs: "For updates or to submit additional documents, please contact us.",
    customerService: "Customer Service:",
    csWorkTime: 'Mon-Fri, 9:00 - 17:00 EST',
    infoUpdate: "Information update",
    accountInfo: "Account Information",
    accountInfoDesc: "Amendment Form, Bank Information, Update ID, etc.",
    fundSource: "Source of Fund",
    other: "Other",
    start: "Start",
    submitAdditionalInfo:'Please submit the additional information as necessary.',
    submitNeededInfo:'Please submit the information that needs to be updated.',
    amendmentFormTip:'VirgoCX Direct amendment form, please make sure you filled all necessary fields and signed',
    sofTip:'Any documents can help proof your source of fund which you mentioned during your onboarding; if you do not clear what to upload or if you want to change/modify your source of fund, please contact your account manager first',
    contactRequired:'Contact required',
    accountActive:'Account active',
    tradeConfirmation:'Trade Confirmation',
    twoFaTitle:'2FA Verification',
    confirmEmail:'Confirm Email',
    skipNow:'Skip Now',
    individualAmendmentForm:'Individual Amendment Form',
    entityAmendmentForm:'Entity Amendment Form',
    downloadAmendmentTip:'If you don\'t have an amendment form, please download the one below.',
};
export default dashboard;
