import common from "./en/common";
import header from "./en/header";
import loginRegister from "./en/loginRegister";
import personalInfo from "./en/personalInfo";
import onboarding from "./en/onboarding";
import dashboard from "./en/dashboard";
import tradeConfirmation from "./en/tradeConfirmation";
const en = {
    common,
    header,
    loginRegister,
    personalInfo,
    onboarding,
    dashboard,
    tradeConfirmation
};
export default en;
