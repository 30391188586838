import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { Spin } from "antd";

// 组件
// import Header from '../components/Header';
// import Footer from '../components/Footer';
import NoMatchPage from "../pages/noMatchPage";
//首页走马灯
import HeaderSimple from "components/header_simple";

const ResetPassword = lazy(() => import("../pages/login/resetPassword"));
const Login = lazy(() => import("../pages/login"));
const Onboarding = lazy(() => import("../pages/onboarding"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const SignPage = lazy(() => import("../pages/dashboard/components/signPage"));
const EntityOnboarding = lazy(()=>import('../pages/onboarding/entityOnboarding'))
const Success = lazy(()=>import('../pages/onboarding/success'))
const EntitySign = lazy(() => import("../pages/dashboard/components/entitySign"));
const FormOnboarding = lazy(() => import("../pages/onboarding/formOnboarding"));
const TwoFactor = lazy(() => import("../pages/twoFactor"));
const Activate = lazy(() => import("../pages/activate"));
const TradeConfirmationDetail = lazy(() => import("../pages/dashboard/components/tradeConfirmation/components/tradeConfirmationDetail"));

const Loading = () => {
    return (
        <section
            style={{
                height: "calc(100vh - 312px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Spin size="large" />
        </section>
    );
};

const whitelist = ['/activate']

const RoutesComponents = () => {
    return (
        <>
            <HeaderSimple/>
            <main>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path={"/"}>
                            <Route index element={<Login />}></Route>
                            <Route path={'/dashboard'}>
                                <Route index path={'/dashboard'} element={<Dashboard />}></Route>
                                <Route path={'account'} element={<Dashboard />}></Route>
                                <Route path={'submitDocs'} element={<Dashboard />}></Route>
                                <Route path={'tradeConfirmation'} element={<Dashboard />}></Route>
                                <Route path={'tradeConfirmation/detail/:name'} element={<TradeConfirmationDetail />}></Route>
                                <Route path={'security'} element={<Dashboard />}></Route>
                            </Route>
                            <Route path={"/reset_password"} element={<ResetPassword />}></Route>
                            <Route path={"/sign"} element={<SignPage />}></Route>
                            <Route path={"/onboarding"} element={<Onboarding />}>
                                {/* <Route path=":name" element={<Onboarding />}></Route>
                                <Route path="" element={<Onboarding />}></Route> */}
                            </Route>
                            <Route path={"/form_onboarding"} element={<FormOnboarding />}></Route>
                            <Route path={"/entity_onboarding"} element={<EntityOnboarding />}>
                            </Route>
                            <Route path={"/success"} element={<Success />}>
                            </Route>
                            <Route path="*" element={<NoMatchPage />} />
                            <Route path={"/entitySign"} element={<EntitySign />}></Route>
                            <Route path={"/twoFactor"} element={<TwoFactor />}></Route>
                            <Route path={"/activate"} element={<Activate />}></Route>
                        </Route>
                    </Routes>
                </Suspense>
            </main>
        </>
    );
};

export default RoutesComponents;
